<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form">
                    <v-card :elevation="1">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0 mt-1">
                                    <v-row no-gutters>
                                        <v-col cols="4" offset="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="5">Consumption Invoice </v-col>
                                                <v-col cols="7">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="consumption"
                                                        @focus="$store.dispatch('consumption/getConsumptionInvoice')"
                                                        :items="$store.getters['consumption/invoices']"
                                                        :loading="$store.getters['consumption/loading']"
                                                        item-text="invoice_id"
                                                        item-value="id"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <consumption-invoice :consumptionId="invoiceId" v-if="show"></consumption-invoice>
        </v-row>
    </v-container>
</template>

<script>
import consumptionInvoice from '../../components/ConsumptionInvoice';
export default {
    name: 'ConsumptionReport',

    components: {
		"consumption-invoice": consumptionInvoice
    },

    data: ()=> ({
        consumption: null,
        invoiceId: null,
        show: false,
    }),

    watch: {
        consumption(consumption) {
            if (consumption == null) return
            this.invoiceId = consumption.id;
            this.show = true;
        },
    },
}
</script>

<style lang="scss" scoped>
</style>